import "./protocolNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProtocol } from "../../contexts/protocol";
import { useCartridge } from "../../contexts/cartridge";
// api
import { queryJsonArray, deleteRowById, readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import Switch from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { alert, confirm } from "devextreme/ui/dialog";

export default function ProtocolNew() {
  const { user } = useAuth();
  const protocolContext = useProtocol();
  const cartridgeContext = useCartridge();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("New Protocol");
  const [protocolNameList, setProtocolNameList] = useState<Array<string>>([]);
  const [protocolName, setProtocolName] = useState("");
  const [isDeviceModelSelectBoxHidden, setIsDeviceModelSelectBoxHidden] = useState(false);
  const [isDeviceModelTextBoxHidden, setIsDeviceModelTextBoxHidden] = useState(true);
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceModelList, setDeviceModelList] = useState<Array<string>>([]);

  const [isAuxModuleSelectBoxHidden, setIsAuxModuleSelectBoxHidden] = useState(false);
  const [isAuxModuleTextBoxHidden, setIsAuxModuleTextBoxHidden] = useState(true);
  const [auxModule, setAuxModule] = useState("");
  const [auxModuleList, setAuxModuleList] = useState<Array<string>>([]);

  const [cartridge, setCartridge] = useState("");
  const [cartridgeList, setCartridgeList] = useState<Array<string>>([]);
  const [isCartridgeSelectBoxHidden, setIsCartridgeSelectBoxHidden] = useState(false);
  const [isCartridgeTextBoxHidden, setIsCartridgeTextBoxHidden] = useState(true);
  const [outflow, setOutflow] = useState("");
  const [isOutflowReadOnly, setIsOutflowReadOnly] = useState(true);
  const [mixingRatio, setMixingRatio] = useState("");
  const [isMixingRatioReadOnly, setIsMixingRatioReadOnly] = useState(true);

  const [freq, setFreq] = useState("");
  const [isFreqReadOnly, setIsFreqReadOnly] = useState(true);
  const [voltage, setVoltage] = useState("");
  const [isVoltageReadOnly, setIsVoltageReadOnly] = useState(true);
  const [duty, setDuty] = useState("50");
  const [isDutyReadOnly, setIsDutyReadOnly] = useState(true);
  const [coolerSwitch, setCoolerSwitch] = useState(true);
  const [coolerT, setCoolerT] = useState("");
  const [maxVolumeSwitch, setMaxVolumeSwitch] = useState(true);
  const [harvestVolume, setHarvestVolume] = useState("0");
  const [isHarvestVolumeReadOnly, setIsHarvestVolumeReadOnly] = useState(true);
  const [numberOfUse, setNumberOfUse] = useState("0");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;
  let newDuty: string = "50";

  useEffect(() => {
    let userId: string = "";
    if (user) {
      userId = user.id;
    }
    //if (cartridgeContext) {
    //setCartridgeList(cartridgeContext.codeList);
    //}

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
    }

    // SelectBox의 값을 설정할 때에는 dataSource의 list 안에 있는 값만 적용된다. 다른 값을 설정하면 화면에는 선택 안된 걸로 표시된다.
    if (0 < protocolContext.id.length) {
      // edit
      setPageTitle("Edit Protocol");
      setProtocolName(protocolContext.id);
      setDeviceModel(protocolContext.deviceModel);
      setAuxModule(protocolContext.auxModule);
      setIsDeviceModelSelectBoxHidden(true);
      setIsDeviceModelTextBoxHidden(false);
      setIsAuxModuleSelectBoxHidden(true);
      setIsAuxModuleTextBoxHidden(false);
      setCartridge(protocolContext.cartridge);
      setIsCartridgeSelectBoxHidden(true);
      setIsCartridgeTextBoxHidden(false);
      setOutflow(protocolContext.outflow);
      setMixingRatio(protocolContext.mixingRatio);
      setFreq(protocolContext.freq);
      setVoltage(protocolContext.voltage);
      updateDuty(protocolContext.voltage, protocolContext.deviceModel);
      setIsVoltageReadOnly(true);
      newDuty = protocolContext.duty;
      setDuty(newDuty);
      let coolerModeN: number = U.intFromString(protocolContext.coolerMode, 0);
      if (coolerModeN == 0) setCoolerSwitch(false);
      else setCoolerSwitch(true);
      setCoolerT(protocolContext.coolerT);
      let maxVolumeN: number = U.intFromString(protocolContext.maxVolume, 0);
      if (maxVolumeN == 0) {
        setMaxVolumeSwitch(false);
        setIsHarvestVolumeReadOnly(false);
      } else {
        setMaxVolumeSwitch(true);
        setIsHarvestVolumeReadOnly(true);
      }
      setHarvestVolume(protocolContext.harvestVolume);
      setNumberOfUse(protocolContext.numberOfUse);
    } else {
      setPageTitle("New Protocol");
      setIsDeviceModelSelectBoxHidden(false);
      setIsDeviceModelTextBoxHidden(true);
      setIsAuxModuleSelectBoxHidden(false);
      setIsAuxModuleTextBoxHidden(true);
      setIsCartridgeSelectBoxHidden(false);
      setIsCartridgeTextBoxHidden(true);
      setMixingRatio("2");
      setFreq("10");
      setVoltage("1200");
      updateDuty("1200", "");
      setIsVoltageReadOnly(false);
      newDuty = "50";
      setDuty("50");
      setCoolerSwitch(true);
      setCoolerT("0");
      setMaxVolumeSwitch(true);
      setIsHarvestVolumeReadOnly(true);
      setHarvestVolume("0");
      setNumberOfUse("0");
    }

    // admin user
    if (userId.toLowerCase() === "admin") {
      setIsOutflowReadOnly(false);
      setIsMixingRatioReadOnly(false);
      setIsFreqReadOnly(false);
      setIsDutyReadOnly(false);
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT model FROM device";
    query += ` WHERE ownerAffiliation = '${userAffiliation}'`;
    query += " ORDER BY model Asc";
    queryJsonArray("/project/queryprotocol", query).then((deviceJson: Array<Object>) => {
      let dmList = new Array<string>();
      let hasBadger700t: boolean = false;
      let hasBadger700d: boolean = false;
      let hasBadger2: boolean = false;
      let hasMenorah: boolean = false;
      for (let i = 0; i < deviceJson.length; i++) {
        let modelName: string = U.stringValueFromJson(deviceJson[i], "model");
        if (modelName === "Badger 700T") hasBadger700t = true;
        if (modelName === "Badger 700D") hasBadger700d = true;
        if (modelName === "Badger-II") hasBadger2 = true;
        if (modelName === "Menorah") hasMenorah = true;
      }
      if (hasBadger700t) dmList.push("Badger 700T");
      if (hasBadger700d) dmList.push("Badger 700D");
      if (hasBadger2) dmList.push("Badger-II");
      if (hasMenorah) dmList.push("Menorah");
      setDeviceModelList(dmList);
    });

    query = "SELECT id FROM protocol";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/queryprotocol", query).then((jsonData: Array<Object>) => {
      let nameList = [];
      for (let i = 0; i < jsonData.length; i++) {
        nameList.push(U.stringValueFromJson(jsonData[i], "id"));
      }
      setProtocolNameList(nameList);
    });
  }

  function updateProtocolName(currentCartridge: string, currentVoltage: string, currentDuty: string) {
    // FCC-2310-1000V-2409006
    // -> FCC-2310_1000V_50_24001
    let currentVoltageF: number = U.floatFromString(currentVoltage, 0);
    let voltagePart: string = `${currentVoltageF}V`;
    let dutyPart: string = `${currentDuty}`;
    if (dutyPart.length == 1) dutyPart = "0" + dutyPart;

    let date = new Date();
    let year: number = date.getFullYear(); // 2024
    let strYear: string = year.toString();
    let yy: string = strYear.substring(2, 4);

    let maxIndex: number = 0;
    for (let i = 0; i < protocolNameList.length; i++) {
      let listName: string = protocolNameList[i];
      if (listName.length < 7) continue;
      let listYy: string = listName.substring(listName.length - 5, listName.length - 3);
      let listIndex: string = listName.substring(listName.length - 3, listName.length);
      if (yy === listYy) {
        let index: number = U.intFromString(listIndex, 0);
        if (maxIndex < index) maxIndex = index;
      }
    }
    let strNewIndex: string = "";
    let newIndex: number = maxIndex + 1;
    if (newIndex < 10) strNewIndex = "00" + newIndex.toString();
    else {
      if (newIndex < 100) strNewIndex = "0" + newIndex.toString();
      else strNewIndex = newIndex.toString();
    }
    if (strNewIndex.length > 3) strNewIndex = "999";
    let newName: string = `${currentCartridge}_${voltagePart}_${dutyPart}_${yy}${strNewIndex}`;
    setProtocolName(newName);
  }

  function onDeviceModelSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDeviceModel(str);
    if (str === "Badger 700T" || str === "Badger 700D") {
      let amList = new Array<string>();
      amList.push("");
      amList.push("Turtle-I");
      amList.push("Turtle-II");
      setAuxModuleList(amList);
    } else {
      let amList = new Array<string>();
      setAuxModuleList(amList);
    }
    setAuxModule("");

    setCartridge("");
    setOutflow("");
    setMixingRatio("");
    setFreq("");
    updateDuty(voltage, str);
    updateCartridgeList(str, "");
    updateProtocolName("", voltage, newDuty);
  }

  function onAuxModuleSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setAuxModule(str);
    updateCartridgeList(deviceModel, str);
    updateProtocolName("", voltage, newDuty);
  }

  function updateCartridgeList(currentDeviceModel: string, currentAuxModule: string) {
    if (!cartridgeContext) return;
    let modelCategory: string = "";
    if (currentDeviceModel === "Badger 700T" || currentDeviceModel === "Badger 700D") {
      modelCategory = "Badger";
      if (currentAuxModule === "Turtle-I" || currentAuxModule === "Turtle-II") modelCategory = "Turtle";
    } else {
      modelCategory = currentDeviceModel;
    }

    let catList = new Array<string>();
    for (let i = 0; i < cartridgeContext.modelCategoryList.length; i++) {
      if (modelCategory === cartridgeContext.modelCategoryList[i]) {
        catList.push(cartridgeContext.catNoList[i]);
      }
    }
    setCartridgeList(catList);
  }

  function onCartridgeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCartridge(str);
    let newOutflow: string = "";
    for (let i = 0; i < cartridgeContext.catNoList.length; i++) {
      if (cartridgeContext.catNoList[i] === str) {
        newOutflow = cartridgeContext.outflowList[i].toString();
        break;
      }
    }
    setOutflow(newOutflow);
    setMixingRatio("2");
    setFreq("10");
    updateProtocolName(str, voltage, duty);
  }
  function onHarvestVolumeTextBoxValueChanged(e: string) {
    setHarvestVolume(e);
  }

  function onOutflowTextBoxValueChanged(e: string) {
    setOutflow(e);
  }
  function onMixingRatioTextBoxValueChanged(e: string) {
    setMixingRatio(e);
  }
  function onFreqTextBoxValueChanged(e: string) {
    setFreq(e);
  }
  function onVoltageTextBoxValueChanged(e: string) {
    setVoltage(e);
    updateDuty(e, deviceModel);
    updateProtocolName(cartridge, e, newDuty);
  }
  function updateDuty(strVoltage: string, strDeviceModel: string) {
    if (strDeviceModel === "Badger 700D") {
      let voltageF: number = U.floatFromString(strVoltage, 0);
      if (voltageF < 1500) newDuty = "50";
      if (1500 <= voltageF && voltageF < 1600) newDuty = "45";
      if (1600 <= voltageF && voltageF < 1700) newDuty = "40";
      if (1700 <= voltageF && voltageF < 1800) newDuty = "35";
      if (1800 <= voltageF && voltageF < 1900) newDuty = "31";
      if (1900 <= voltageF && voltageF < 2000) newDuty = "28";
      if (2000 <= voltageF) newDuty = "25";
    } else {
      newDuty = "50";
    }
    setDuty(newDuty);
  }

  function onDutyTextBoxValueChanged(e: string) {
    newDuty = e;
    setDuty(e);
    updateProtocolName(cartridge, voltage, newDuty);
  }
  function onCoolerSwitchValueChanged(e: boolean) {
    setCoolerSwitch(e);
  }
  function onMaxVolumeSwitchValueChanged(e: boolean) {
    setMaxVolumeSwitch(e);
    if (e === true) {
      setIsHarvestVolumeReadOnly(true);
      setHarvestVolume("0");
    } else {
      setIsHarvestVolumeReadOnly(false);
    }
  }

  async function onUploadButtonClicked() {
    if (protocolName.length === 0) {
      alert("Enter protocol name!", "Error");
      return;
    }
    if (cartridge.length === 0) {
      alert("Select cartridge!", "Error");
      return;
    }

    let outflowF: number = U.floatFromString(outflow, 0);
    let mixingRatioF: number = U.floatFromString(mixingRatio, 0);
    let freqF: number = U.floatFromString(freq, 0);
    if (freqF !== 1 && freqF !== 2 && freqF !== 3 && freqF !== 4 && freqF !== 5 && freqF !== 6 && freqF !== 7 && freqF !== 8 && freqF !== 9 && freqF !== 10) {
      alert("Enter integer value between 1 ~ 10 for frequency!", "Error");
      return;
    }
    let voltageF: number = U.floatFromString(voltage, -1);
    if (voltageF < 0) {
      alert("Enter proper value for voltage!", "Error");
      return;
    }

    let dutyF: number = U.floatFromString(duty, 0);
    let coolerModeN: number = 1;
    if (coolerSwitch === false) coolerModeN = 0;
    let coolerTF: number = U.floatFromString(coolerT, 0);
    // Cooling Algorithm 으로써 voltage에 따라 coolerT를 자동 설정한다.
    // 1400 V ~ 2000 V: -5C
    // 1300 V: -1.3C
    // 1200 V: 2.5C
    // 1100 V: 6.3C
    // 0 V ~ 1000 V: 10C
    if (coolerModeN == 1) {
      if (1400 <= voltageF) coolerTF = -5;
      else {
        if (voltageF <= 1000) coolerTF = 10;
        else {
          // 1000 ~ 1400 V
          coolerTF = 10 + ((voltageF - 1000.0) / 400.0) * -15.0;
          // 소수점 1자리로 처리
          let str: string = coolerTF.toFixed(1);
          coolerTF = parseFloat(str);
        }
      }
    }
    let harvestVolumeF: number = U.floatFromString(harvestVolume, 0);
    let maxVolumeN: number = 1;
    if (maxVolumeSwitch === false) maxVolumeN = 0;
    else harvestVolumeF = 0;
    let numberOfUseF: number = U.floatFromString(numberOfUse, 0);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let protocolName2: string = U.replaceBrace(protocolName);

    let obj: Object = {
      id: protocolName2,
      deviceModel: deviceModel,
      auxModule: auxModule,
      cartridge: cartridge,
      outflow: outflowF,
      mixingRatio: mixingRatioF,
      freq: freqF,
      voltage: voltageF,
      duty: dutyF,
      coolerMode: coolerModeN,
      coolerT: coolerTF,
      maxVolume: maxVolumeN,
      harvestVolume: harvestVolumeF,
      numberOfUse: numberOfUseF,
    };

    await uploadJson("/project/uploadprotocol", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Same id exists! Do you want to overwrite?", "Warning");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwriteprotocol", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Protocol Name" value={protocolName} readOnly={true} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"} hidden={isDeviceModelSelectBoxHidden}>
              <SelectBox label="Device Model" dataSource={deviceModelList} value={deviceModel} width={300} onSelectionChanged={onDeviceModelSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isDeviceModelTextBoxHidden}>
              <TextBox label="Device Model" value={deviceModel} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isAuxModuleSelectBoxHidden}>
              <SelectBox label="Aux Module" dataSource={auxModuleList} value={auxModule} width={300} onSelectionChanged={onAuxModuleSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isAuxModuleTextBoxHidden}>
              <TextBox label="Aux Module" value={auxModule} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isCartridgeSelectBoxHidden}>
              <SelectBox label="Cartridge" dataSource={cartridgeList} value={cartridge} width={300} onSelectionChanged={onCartridgeSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isCartridgeTextBoxHidden}>
              <TextBox label="Cartridge" value={cartridge} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Outflow (mL/h)"
                value={outflow}
                readOnly={isOutflowReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onOutflowTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mixing Ratio (Mat/Cell)"
                value={mixingRatio}
                readOnly={isMixingRatioReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onMixingRatioTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Frequency (kHz)"
                value={freq}
                readOnly={isFreqReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onFreqTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Voltage (V)"
                value={voltage}
                readOnly={isVoltageReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onVoltageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Duty (%)"
                value={duty}
                readOnly={isDutyReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onDutyTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Cooler</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={coolerSwitch} onValueChange={onCoolerSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Max Volume</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={maxVolumeSwitch} onValueChange={onMaxVolumeSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Harvest Volume (mL)"
                value={harvestVolume}
                readOnly={isHarvestVolumeReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onHarvestVolumeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Number of Use" value={numberOfUse} readOnly={true} valueChangeEvent="keyup" width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
