import "./calibrationBackup.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { queryJsonArray } from "../../api/auth";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import * as U from "../../utils";

export default function CalibrationBackup() {
  const { user } = useAuth();

  const [calibrationListJsonData, setCalibrationListJsonData] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    initializeCalibrationList();
  }, []);

  function initializeCalibrationList() {
    let query = "SELECT * FROM calibration";
    query += " ORDER BY deviceSerial Asc";
    queryJsonArray("/login/query", query).then((json: Array<Object>) => {
      setCalibrationListJsonData(json);
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Calibration Backup</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div>
            <DataGrid dataSource={calibrationListJsonData} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="updated_at" caption="Backuped at" dataType="datetime" />
              <Column dataField="affiliation" caption="Affiliation" />
              <Column dataField="userId" caption="User ID" />
              <Column dataField="modelName" caption="Model" />
              <Column dataField="swVersion" caption="SW Ver." />
              <Column dataField="mainBoardVersion" caption="MainBoard Ver." />
              <Column dataField="sdCardBoardVersion" caption="SD Card Board Ver." />
              <Column dataField="ampBoardVersion" caption="Amp Board Ver." />
              <Column dataField="stepMotorBoardVersion" caption="Step Motor Board Ver." />
              <Column dataField="adminPassword" caption="adminPassword" />
              <Column dataField="mainBoardSetT" caption="MainBoard SetT" format={"#,##0.#"} />

              <Column dataField="motor10IsReverseDir" caption="Motor10 Reverse Dir." />
              <Column dataField="motor10SoftwareLimitPosition" caption="Motor10 Software Limit Position" format={"#,##0.##"} />
              <Column dataField="motor11IsReverseDir" caption="Motor11 Reverse Dir." />
              <Column dataField="motor11SoftwareLimitPosition" caption="Motor11 Software Limit Position" format={"#,##0.##"} />
              <Column dataField="motor12IsReverseDir" caption="Motor12 Reverse Dir." />
              <Column dataField="motor12SoftwareLimitPosition" caption="Motor12 Software Limit Position" format={"#,##0.##"} />
              <Column dataField="motor20IsReverseDir" caption="Motor20 Reverse Dir." />
              <Column dataField="motor20SoftwareLimitPosition" caption="Motor20 Software Limit Position" format={"#,##0.##"} />
              <Column dataField="motor21IsReverseDir" caption="Motor21 Reverse Dir." />
              <Column dataField="motor21SoftwareLimitPosition" caption="Motor21 Software Limit Position" format={"#,##0.##"} />

              <Column dataField="fcc2310Motor10Zvp" caption="fcc2310 Motor10 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc2310Motor11Zvp" caption="fcc2310 Motor11 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc2350Motor10Zvp" caption="fcc2350 Motor10 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc2350Motor11Zvp" caption="fcc2350 Motor11 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc3321Motor10Zvp" caption="fcc3321 Motor10 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc3321Motor11Zvp" caption="fcc3321 Motor11 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc3351Motor10Zvp" caption="fcc3351 Motor10 ZVP" format={"#,##0.##"} />
              <Column dataField="fcc3351Motor11Zvp" caption="fcc3351 Motor11 ZVP" format={"#,##0.##"} />

              <Column dataField="motor10StepOutKgf" caption="Motor10 Step Out (kgf)" format={"#,##0.##"} />
              <Column dataField="motor11StepOutKgf" caption="Motor11 Step Out (kgf)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm1" caption="Motor10 1kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm2" caption="Motor10 2kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm3" caption="Motor10 3kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm4" caption="Motor10 4kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm5" caption="Motor10 5kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor10Kohm6" caption="Motor10 6kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm1" caption="Motor11 1kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm2" caption="Motor11 2kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm3" caption="Motor11 3kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm4" caption="Motor11 4kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm5" caption="Motor11 5kgf (kohm)" format={"#,##0.##"} />
              <Column dataField="motor11Kohm6" caption="Motor11 6kgf (kohm)" format={"#,##0.##"} />

              <Column dataField="ampPotentiometer1" caption="Amp Potentiometer1" />
              <Column dataField="ampPulseAdd" caption="Amp Pulse Add" />

              <Column dataField="nullCurrent500v20Duty" caption="Null Current 500V 20%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1000v20Duty" caption="Null Current 1000V 20%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1500v20Duty" caption="Null Current 1500V 20%" format={"#,##0.##"} />
              <Column dataField="nullCurrent2000v20Duty" caption="Null Current 2000V 20%" format={"#,##0.##"} />

              <Column dataField="nullCurrent500v30Duty" caption="Null Current 500V 30%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1000v30Duty" caption="Null Current 1000V 30%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1500v30Duty" caption="Null Current 1500V 30%" format={"#,##0.##"} />
              <Column dataField="nullCurrent2000v30Duty" caption="Null Current 2000V 30%" format={"#,##0.##"} />

              <Column dataField="nullCurrent500v40Duty" caption="Null Current 500V 40%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1000v40Duty" caption="Null Current 1000V 40%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1500v40Duty" caption="Null Current 1500V 40%" format={"#,##0.##"} />
              <Column dataField="nullCurrent2000v40Duty" caption="Null Current 2000V 40%" format={"#,##0.##"} />

              <Column dataField="nullCurrent500v50Duty" caption="Null Current 500V 50%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1000v50Duty" caption="Null Current 1000V 50%" format={"#,##0.##"} />
              <Column dataField="nullCurrent1500v50Duty" caption="Null Current 1500V 50%" format={"#,##0.##"} />
              <Column dataField="nullCurrent2000v50Duty" caption="Null Current 2000V 50%" format={"#,##0.##"} />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="deviceSerial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
