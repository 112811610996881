import "./userNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUser } from "../../contexts/user";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import Switch from "devextreme-react/switch";
import { alert, confirm } from "devextreme/ui/dialog";

export default function UserNew() {
  const { user } = useAuth();
  const userContext = useUser();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("New User");
  const [id, setId] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [isIdReadOnly, setIsIdReadOnly] = useState(false);
  const [isGlobalDivHidden, setIsGlobalDivHidden] = useState(true);

  const [operatorSwitch, setOperatorSwitch] = useState(true);
  const [txResultRSwitch, setTxResultRSwitch] = useState(true);
  const [txResultDSwitch, setTxResultDSwitch] = useState(true);
  const [deviceRWSwitch, setDeviceRWSwitch] = useState(true);
  const [deviceDSwitch, setDeviceDSwitch] = useState(true);
  const [libraryRSwitch, setLibraryRSwitch] = useState(true);
  const [libraryWSwitch, setLibraryWSwitch] = useState(true);
  const [libraryDSwitch, setLibraryDSwitch] = useState(true);
  const [protocolRSwitch, setProtocolRSwitch] = useState(true);
  const [protocolWSwitch, setProtocolWSwitch] = useState(true);
  const [protocolDSwitch, setProtocolDSwitch] = useState(true);
  const [projectRSwitch, setProjectRSwitch] = useState(true);
  const [projectWSwitch, setProjectWSwitch] = useState(true);
  const [projectDSwitch, setProjectDSwitch] = useState(true);
  const [globalRSwitch, setGlobalRSwitch] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let userAffiliation = "";
    if (user) {
      userAffiliation = user.affiliation;
      if (userAffiliation.toLowerCase() === "femtobiomed") {
        setIsGlobalDivHidden(false);
      } else {
        setIsGlobalDivHidden(true);
      }
    }

    if (0 < userContext.id.length) {
      // edit
      setPageTitle("Edit User");
      setIsIdReadOnly(true);
      setId(userContext.id);
      setAffiliation(userContext.affiliation);
      // (tx) [0] Operator, [1] TX Sample R, [2] D, [3] Device R & W, [4] D
      let acc_tx: number = U.intFromString(userContext.acc_tx, 0);
      // (proj) [0] Project R, [1] W, [2] D, [3] Protocol R, [4] W, [5] D, [6] Cell & Mat R, [7] W, [8] D
      let acc_proj: number = U.intFromString(userContext.acc_proj, 0);
      setOperatorSwitch(U.bitAt(acc_tx, 0));
      setTxResultRSwitch(U.bitAt(acc_tx, 1));
      setTxResultDSwitch(U.bitAt(acc_tx, 2));
      setDeviceRWSwitch(U.bitAt(acc_tx, 3));
      setDeviceDSwitch(U.bitAt(acc_tx, 4));
      setGlobalRSwitch(U.bitAt(acc_tx, 5));

      setLibraryRSwitch(U.bitAt(acc_proj, 6));
      setLibraryWSwitch(U.bitAt(acc_proj, 7));
      setLibraryDSwitch(U.bitAt(acc_proj, 8));
      setProtocolRSwitch(U.bitAt(acc_proj, 3));
      setProtocolWSwitch(U.bitAt(acc_proj, 4));
      setProtocolDSwitch(U.bitAt(acc_proj, 5));
      setProjectRSwitch(U.bitAt(acc_proj, 0));
      setProjectWSwitch(U.bitAt(acc_proj, 1));
      setProjectDSwitch(U.bitAt(acc_proj, 2));
    } else {
      setPageTitle("New User");
      setId("");
      setIsIdReadOnly(false);
      setAffiliation(userContext.affiliation);
      setOperatorSwitch(true);
      setTxResultRSwitch(true);
      setTxResultDSwitch(true);
      setDeviceRWSwitch(true);
      setDeviceDSwitch(true);
      setGlobalRSwitch(false);

      setLibraryRSwitch(true);
      setLibraryWSwitch(true);
      setLibraryDSwitch(true);
      setProtocolRSwitch(true);
      setProtocolWSwitch(true);
      setProtocolDSwitch(true);
      setProjectRSwitch(true);
      setProjectWSwitch(true);
      setProjectDSwitch(true);
    }
  }, []);

  function onIdTextBoxValueChanged(e: string) {
    setId(e);
  }

  function onOperatorSwitchValueChanged(e: boolean) {
    setOperatorSwitch(e);
  }

  function onTxResultRSwitchValueChanged(e: boolean) {
    setTxResultRSwitch(e);
  }
  function onTxResultDSwitchValueChanged(e: boolean) {
    setTxResultDSwitch(e);
  }
  function onDeviceRWSwitchValueChanged(e: boolean) {
    setDeviceRWSwitch(e);
  }
  function onDeviceDSwitchValueChanged(e: boolean) {
    setDeviceDSwitch(e);
  }
  function onLibraryRSwitchValueChanged(e: boolean) {
    setLibraryRSwitch(e);
  }
  function onLibraryWSwitchValueChanged(e: boolean) {
    setLibraryWSwitch(e);
  }
  function onLibraryDSwitchValueChanged(e: boolean) {
    setLibraryDSwitch(e);
  }
  function onProtocolRSwitchValueChanged(e: boolean) {
    setProtocolRSwitch(e);
  }
  function onProtocolWSwitchValueChanged(e: boolean) {
    setProtocolWSwitch(e);
  }
  function onProtocolDSwitchValueChanged(e: boolean) {
    setProtocolDSwitch(e);
  }
  function onProjectRSwitchValueChanged(e: boolean) {
    setProjectRSwitch(e);
  }
  function onProjectWSwitchValueChanged(e: boolean) {
    setProjectWSwitch(e);
  }
  function onProjectDSwitchValueChanged(e: boolean) {
    setProjectDSwitch(e);
  }
  function onGlobalRSwitchValueChanged(e: boolean) {
    setGlobalRSwitch(e);
  }

  async function onUploadButtonClicked() {
    if (id.length === 0) {
      alert("Enter ID!", "Error");
      return;
    }

    if (id.toLowerCase() === "admin") {
      alert(`${id} is not allowed as ID!`, "Error");
      return;
    }

    let acc_tx: number = 0,
      acc_proj: number = 0;
    // (tx) [0] Operator, [1] TX Sample R, [2] D, [3] Device R & W, [4] D
    if (operatorSwitch) acc_tx += Math.pow(2, 0);
    if (txResultRSwitch) acc_tx += Math.pow(2, 1);
    if (txResultDSwitch) acc_tx += Math.pow(2, 2);
    if (deviceRWSwitch) acc_tx += Math.pow(2, 3);
    if (deviceDSwitch) acc_tx += Math.pow(2, 4);
    if (globalRSwitch) acc_tx += Math.pow(2, 5);

    // (proj) [0] Project R, [1] W, [2] D, [3] Protocol R, [4] W, [5] D, [6] Cell & Mat R, [7] W, [8] D
    if (projectRSwitch) acc_proj += Math.pow(2, 0);
    if (projectWSwitch) acc_proj += Math.pow(2, 1);
    if (projectDSwitch) acc_proj += Math.pow(2, 2);
    if (protocolRSwitch) acc_proj += Math.pow(2, 3);
    if (protocolWSwitch) acc_proj += Math.pow(2, 4);
    if (protocolDSwitch) acc_proj += Math.pow(2, 5);
    if (libraryRSwitch) acc_proj += Math.pow(2, 6);
    if (libraryWSwitch) acc_proj += Math.pow(2, 7);
    if (libraryDSwitch) acc_proj += Math.pow(2, 8);

    let obj: Object = {
      id: id,
      affiliation: affiliation,
      acc_tx: acc_tx,
      acc_proj: acc_proj,
    };

    await uploadJson("/login/uploaduser", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="ID"
                value={id}
                readOnly={isIdReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onIdTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Operator</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={operatorSwitch} onValueChange={onOperatorSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>TX Result (R)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={txResultRSwitch} onValueChange={onTxResultRSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>TX Result (Del)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={txResultDSwitch} onValueChange={onTxResultDSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Device (R & W)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={deviceRWSwitch} onValueChange={onDeviceRWSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Device (Del)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={deviceDSwitch} onValueChange={onDeviceDSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Library (R)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={libraryRSwitch} onValueChange={onLibraryRSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Library (W)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={libraryWSwitch} onValueChange={onLibraryWSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Library (Del)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={libraryDSwitch} onValueChange={onLibraryDSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Protocol (R)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={protocolRSwitch} onValueChange={onProtocolRSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Protocol (W)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={protocolWSwitch} onValueChange={onProtocolWSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Protocol (Del)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={protocolDSwitch} onValueChange={onProtocolDSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Project (R)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={projectRSwitch} onValueChange={onProjectRSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Project (W)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={projectWSwitch} onValueChange={onProjectWSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Project (Del)</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={projectDSwitch} onValueChange={onProjectDSwitchValueChanged} width={40} />
              </div>
            </div>
            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"} hidden={isGlobalDivHidden}>
                  Global
                </p>
              </div>
              <div className={"flex-switch1"} hidden={isGlobalDivHidden}>
                <Switch value={globalRSwitch} onValueChange={onGlobalRSwitchValueChanged} width={40} />
              </div>
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
