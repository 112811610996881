import "./globalAccount.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { queryJsonArray } from "../../api/auth";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import * as U from "../../utils";

export default function GlobalAccount() {
  const { user } = useAuth();

  const [accountListJsonData, setAccountListJsonData] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    initializeAccountList();
  }, []);

  function initializeAccountList() {
    let query = "SELECT * FROM user";
    query += " ORDER BY affiliation Asc, id Asc";
    queryJsonArray("/login/queryglobal", query).then((json: Array<Object>) => {
      // 마지막 메시지 수신 후 6분 이상 지났으면 status2 = Offline으로 표시해준다.
      setAccountListJsonData(json);
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Global Account List</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div>
            <DataGrid dataSource={accountListJsonData} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="affiliation" caption="Affiliation" />
              <Column dataField="id" caption="User ID" />
              <Column dataField="accessed_at" caption="Last Access" dataType="datetime" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="affiliation" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
