import "./globalDevice.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { queryJsonArray } from "../../api/auth";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import * as U from "../../utils";

export default function GlobalDevice() {
  const { user } = useAuth();

  const [deviceListJsonData, setDeviceListJsonData] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    initializeDeviceList();
  }, []);

  function initializeDeviceList() {
    let query = "SELECT * FROM device";
    query += " ORDER BY ownerAffiliation Asc, deviceSerial Asc";
    queryJsonArray("/login/queryglobal", query).then((json: Array<Object>) => {
      // 마지막 메시지 수신 후 6분 이상 지났으면 status2 = Offline으로 표시해준다.
      let newJsonArray = new Array<Object>();
      let dateNow = new Date(Date.now());
      for (let i = 0; i < json.length; i++) {
        let updated_at = U.dateValueFromJson(json[i], "updated_at");
        let elapsedTime_ms = dateNow.getTime() - updated_at.getTime();
        let status2: string = U.stringValueFromJson(json[i], "status");
        if (360000 < elapsedTime_ms) status2 = "Offline";
        let obj: Object | null = U.addStringToJson(json[i], "status2", status2);
        if (obj !== null) newJsonArray.push(obj);
      }
      setDeviceListJsonData(newJsonArray);
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Global Device List</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div>
            <DataGrid dataSource={deviceListJsonData} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="ownerAffiliation" caption="Owner Affiliation" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="status2" caption="Status" />
              <Column dataField="affiliation" caption="Last Login Affiliation" />
              <Column dataField="userId" caption="Last Login User" />
              <Column dataField="updated_at" caption="Last Update" dataType="datetime" />
              <Column dataField="project" caption="Project" />
              <Column dataField="model" caption="Model" />
              <Column dataField="auxModule" caption="Aux Module" />
              <Column dataField="ampType" caption="Amp Type" />
              <Column dataField="swVersion" caption="SW Version" />
              <Column dataField="timeZone" caption="Time Zone" />
              <Column dataField="location" caption="Location" />
              <Column dataField="temperature" caption="Temperature (°C)" />
              <Column dataField="humidity" caption="Humidity (%RH)" />
              <Column dataField="fanTime" caption="Fan Time (hr)" format={"#,##0.#"} />
              <Column dataField="fcc2310" caption="FCC-2310 Usage" />
              <Column dataField="fcc332t" caption="FCC-332T Usage" />
              <Column dataField="fcc335t" caption="FCC-335T Usage" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="ownerAffiliation" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
